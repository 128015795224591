import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
/* import { Srv } from 'src/app/models/srv.model'; */
import { URL_SERVICE } from '../../config/config';
import { UserAPP } from '../models/userAPP.model';
import { isNumeric } from 'rxjs/util/isNumeric';
import { formatDate } from '@angular/common';
/* import { SubirArchivoService } from '../subirArchivo/subir-archivo.service'; */


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  prepareRes(data:any,where?:any,group?:string){
    let ret = [];
    let aux = data;            
    
    if(where === undefined || where == "" ){
      aux = data;
    }else if(typeof(where) == 'object'){
      aux = data;
      for (let field in where) {
        if (typeof(where[field]) == 'string'){
          aux = aux.filter(i => (i[field].toUpperCase().search(where[field].toUpperCase())>=0));
        }else if (typeof(where[field]) == 'number'){
          aux = aux.filter(i => (i[field] == where[field]));
        }
        
      }

    }else if(typeof(where) == 'number' || isNumeric(where)){
        
      aux = data.filter(item => {
        for (let fld in item) {        
            if (typeof(item[fld]) == 'number'){
              if (item[fld] == where){
                return true;
              }
            }
        }
       });

    }else if(typeof(where) == 'string'){    
      aux = data.filter(item => {
        for (let fld in item) {        
            if (typeof(item[fld]) == 'string'){
              if (this.isValidDate(item[fld])){
                let vDate = new Date(item[fld]);                
                if (formatDate(vDate,'dd/MM/yyyy',"es").search(where)>=0){
                  return true;
                }
              }else{
                if (item[fld].toUpperCase().search(where.toUpperCase())>=0){
                  return true;
                }
              }
            }
        }
       });

    }else{
      aux = data;
    }

    if (group === undefined || group == ""){
      ret = aux;
    }else{
      let gro = new Set(aux.map(item => item[group]));
      gro.forEach(g => 
        ret.push({
          Group: g, 
          Items:  aux.filter(i => i[group] === g)
        }
      ));         
    }
    
    return ret;
  }

  isValidDate(value) {
    var dateWrapper = new Date(value);
    return !isNaN(dateWrapper.getDate());
  }


  searchword: EventEmitter<string> = new EventEmitter();
  SetSearchWord(word) {
    this.searchword.emit(word);
  }
  GetSearchWord() {
    return this.searchword;
  }

  

  constructor(
    public http: HttpClient,
  ) { 
    console.log('SharedService Ready!');
  }

  serviceAddUser(user: object) {
    let url = URL_SERVICE + '/addUser';

    return this.http.post(url, user).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  sendMessage(object: object) {
    let url = URL_SERVICE + '/sendMessage';

    return this.http.post(url, object).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceResetPass(pass: string ) {
    let url = URL_SERVICE + '/chgPassAPP';
    return this.http.put(url,{'pass': pass},{responseType: "text",  observe: "response"}).pipe(
      map((resp: any) => {
      return resp;
    }),catchError((err: any) => {
      return throwError(err);
    })
      );
    
    }

      /* coger los datos del usuario */
  public serviceGetData(){
    let url = URL_SERVICE + '/getDataUser';
    return this.http.get(url)
                .pipe(map((res: any) => {
                  return res ;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }


}
