import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';

import { User } from '../../models/user.model';

import { Dev } from '../../models/dev.model';

import { URL_SERVICE } from '../../../config/config';


@Injectable({providedIn: 'root'})
//@Injectable()
export class AuthService {

/* user: string; */
token: string;
company: any;
userMail: any;
role: any;
pass: string;
geo:number;

  constructor(
    public router: Router,
    public http: HttpClient
    ) { 
    this.serviceLoadStorage();
    console.log('AuthService Ready!');
  }

/*   serviceIsLogged (){
    
    try {
      let payload = JSON.parse( atob( this.token.split('.')[1] ));
      return this.checkTokenExp(payload.exp) && (payload.userMail == this.userMail);
    }
    catch(err) {
      return false;
    }    
  
  } */

  private checkTokenExp ( exp: number ): boolean  {
      let tokenExp = new Date( exp * 1000 );
      let now = new Date();

      now.setTime( now.getTime() + ( 1 * 60 * 60 * 1000 ) );

      if ( tokenExp.getTime() > now.getTime() ) {
        return true;
      } else {
        return false;
      }

  }

  serviceLoadStorage() {
   if (localStorage.getItem('token') && localStorage.getItem('user')){
      this.token = localStorage.getItem('token') || '';
      this.userMail = localStorage.getItem('user') || '';
      this.userMail = localStorage.getItem('geo') || '';
    }
  }

  serviceLogout(){
      this.token = ''; 
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.router.navigate(['/sessions/signin']);
  }
  
  serviceLogin (user: User,dev:Dev,remember: boolean = false) {
    let url = URL_SERVICE + '/loginAPP';
    let params = { login: user, device: dev }
    return this.http.post(url, params)
                .pipe(map((res: any) => {
                  localStorage.setItem('user',user.USUARIO);
                  localStorage.setItem('token',res.token);

                  this.userMail = user.USUARIO;
                  this.token = res.token;

                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );

  }
  serviceRecPass (user: string) {
     let url = URL_SERVICE + '/recPass/' + user;
    return this.http.post(url, user)
                .pipe(map((res: any) => {                                    
                  return res;
                }),catchError((err: any) => {
                    this.resolveError(err);
                    return throwError(err);
                })
              );
  }


  resolveError(err:any) {
    if (err.status>=400 && err.status<=499){
      this.router.navigate(['/login'])
    }                  
  }
  

}
